import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'
import CTAButton from '@components/elements/CTAButton'

const EspacioUnico = () => {
    const { t } = useTranslation('oasiz')

    return (
        <section className="grid grid-cols-1 lg:grid-cols-2 max-w-7xl mx-auto my-6 px-4">
            <div className="xl:mt-12">
                <h3 className="mx-4 font-hof-clean text-primary text-6xl tracking-wide text-center xl:text-right cursor-default leading-tight relative xl:right-20">{t('homepage.espacio-unico.title')}</h3>
                <div className="w-96 xl:w-80 my-10 relative xl:bottom-20 mx-auto lg:ml-12 xl:ml-0">
                    <p className="prose text-center lg:text-left mx-auto">{t('homepage.espacio-unico.content')}</p>
                    <div className="lg:flex mt-10">
                        <CTAButton path={`/${t('common:routes.opening-access')}`} value={t('common:see-more')} />
                    </div>
                </div>
            </div>
            <div className="lg:order-first mx-12 sm:mx-24 lg:mx-12 xl:ml-0 xl:mr-24">
                <figure className="flex drop-shadow-sm">
                    <Image src={`${process.env.NEXT_PUBLIC_FILES_IQC_MANAGER}/files/oasiz/homepage/fuentes.png`} alt={t('homepage.espacio-unico.title')} width="568" height="522" />
                </figure>
            </div>
        </section>
    )
}

export default EspacioUnico
