import axios from 'axios'

const fetchEntity = (fetchKey: string): Promise<Entity> => {
    return axios
        .get(`${process.env.NEXT_PUBLIC_API_IQCMANAGER}/${fetchKey}`, { headers: { 'content-type': 'application/json' } })
        .then((response) => response.data)
        .catch((error) => Error(`Error fetching IQC Manager. Status code : ${error.response?.status || 500}. Message : ${error.message}`))
}

export default fetchEntity
