import css from 'styled-jsx/css'
import { useCallback, useEffect, useState } from 'react'
import { Transition } from '@headlessui/react'
import Image from 'next/image'

const Chevrons = () => {
    const [isVisible, setIsVisible] = useState(true)

    const listenToScroll = useCallback(() => {
        const heightToHideFrom = 100
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop

        if (winScroll > heightToHideFrom) {
            isVisible && setIsVisible(false)
        } else {
            setIsVisible(true)
        }
    }, [isVisible])

    useEffect(() => {
        window.addEventListener('scroll', listenToScroll)
        return () => window.removeEventListener('scroll', listenToScroll)
    }, [listenToScroll])

    return (
        <>
            <Transition show={isVisible} enter="transition-opacity duration-1000" enterFrom="opacity-0" enterTo="opacity-100" leave="transition-opacity duration-500" leaveFrom="opacity-100" leaveTo="opacity-0">
                <div className="w-screen">
                    <div className="flex absolute inset-x-0 -bottom-20 justify-center">
                        <div>
                            <span className="flex animate-chevron">
                                <Image src="/images/chevron-down.png" width="64" height="64" alt="" />
                            </span>
                            <span className="flex animate-chevron relative -top-10">
                                <Image src="/images/chevron-down.png" width="64" height="64" alt="" />
                            </span>
                            <span className="flex animate-chevron relative -top-20">
                                <Image src="/images/chevron-down.png" width="64" height="64" alt="" />
                            </span>
                        </div>
                    </div>
                </div>
            </Transition>
            <style jsx>{styles}</style>
        </>
    )
}

const styles = css`
    .animate-chevron:nth-child(2) {
        animation-delay: -0.3s;
    }

    .animate-chevron:nth-child(3) {
        animation-delay: -0.6s;
    }
`

export default Chevrons
