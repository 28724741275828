import Image from 'next/image'
import Link from 'next/link'
import useTranslation from 'next-translate/useTranslation'

const ArticlesListItem = ({ article }: { article: Article }) => {
    const { t } = useTranslation('common')

    return (
        <li>
            <Link href={`/${t('routes.articles')}/${article.slug}`}>
                <a className="flex flex-col">
                    <figure className="relative drop-shadow-md mx-12 sm:mx-4">
                        <Image className="mask-blob-1 [mask-size:contain] [mask-repeat:no-repeat] [mask-position:center]" src={article.vignette ?? ''} alt={article.title} width="250" height="250" layout="responsive" />
                    </figure>
                    <div className="grid place-content-center min-h-16 lg:min-h-14">
                        <h2 className="text-center text-lg font-medium tracking-wide uppercase hover:text-gray-500">{article.title}</h2>
                    </div>
                    <p className="mx-8 sm:mx-2 md:mx-4 line-clamp-4 text-center text-gray-900 tracking-wide hover:text-gray-500">
                        {article.heading
                            .replace(/(<([^>]+)>)/gi, ' ')
                            .replace('&nbsp;', ' ')
                            .slice(0, 250)}
                    </p>
                </a>
            </Link>
        </li>
    )
}

export default ArticlesListItem
