import useSWR from 'swr'
import fetchEntity from '@services/iqc/fetchEntity'
import useTranslation from 'next-translate/useTranslation'
import CTAButton from '@components/elements/CTAButton'
import ArticlesListItem from '@components/articles/ArticlesListItem'
import PageTitle from '@components/elements/PageTitle'
import LoadingPage from '@components/elements/LoadingPage'

const fetchKey = `articles.json?active=true&retailParks=${process.env.NEXT_PUBLIC_RETAILPARK}`

const SectionArticles = () => {
    const { t } = useTranslation('common')
    const { data, error } = useSWR(fetchKey, fetchEntity)

    if (error) return <></>
    if (!data) return <LoadingPage />

    const articles: Array<Article> = data

    return (
        <section className="flex flex-col max-w-7xl p-4 sm:p-6 lg:p-8 mx-auto">
            {Array.isArray(articles) && articles.length > 0 ? (
                <>
                    <PageTitle title={t('articles.title')} />
                    <ul className="grid grid-cols-1 sm:hidden lg:grid lg:grid-cols-3 gap-16 mt-8 sm:mt-4 lg:mt-0">
                        {articles.slice(0, 3).map((article: Article) => (
                            <ArticlesListItem key={article.id} article={article} />
                        ))}
                    </ul>
                    <ul className="hidden sm:grid sm:grid-cols-2 lg:hidden gap-16 mt-4">
                        {articles.slice(0, 4).map((article: Article) => (
                            <ArticlesListItem key={article.id} article={article} />
                        ))}
                    </ul>
                </>
            ) : (
                <div className="text-lg text-center italic my-24">{t('articles.no-article')}</div>
            )}
            <CTAButton path={`/${t('routes.articles')}`} value={t('see-more')} className="my-16" />
        </section>
    )
}

export default SectionArticles
