import Routes from '@components/layout/Routes'
import Header from '@components/layout/header/Header'
import Chevrons from '@components/homepage/Chevrons'
import Footer from '@components/layout/footer/Footer'
import Modal from '@components/account/login/Modal'
import LoginContainer from '@components/account/login/LoginContainer'
import { ReactElement } from 'react'

const HomepageLayout = ({ children }: { children: ReactElement }) => {
    return (
        <Routes>
            {(pageTabs) => (
                <>
                    <div className="bg-homepage-hero h-screen bg-cover bg-center bg-no-repeat">
                        <Header pageTabs={pageTabs} />
                        <Chevrons />
                    </div>
                    <main>{children}</main>
                    <Footer pageTabs={pageTabs} />
                    <Modal>
                        <LoginContainer />
                    </Modal>
                </>
            )}
        </Routes>
    )
}

export default HomepageLayout
