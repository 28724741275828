import useTranslation from 'next-translate/useTranslation'
import { ReactElement } from 'react'

const LoadingPage = ({ children }: { children?: ReactElement }) => {
    const { t } = useTranslation('common')

    return (
        <div className="flex justify-center h-120">
            <div className="my-auto text-center space-y-6">
                <span className="text-4xl font-extrabold animate-pulse bg-clip-text text-transparent bg-gradient-to-r from-primary-light to-primary-dark">{t('loading')}...</span>
                {children}
            </div>
        </div>
    )
}

export default LoadingPage
