import useTranslation from 'next-translate/useTranslation'
import CTAButton from '@components/elements/CTAButton'
import Image from 'next/image'

const LugarDondeDisfrutar = () => {
    const { t } = useTranslation('oasiz')

    return (
        <div className="xl:bg-palm bg-no-repeat relative xl:-left-20 xl:top-36">
            <section className="grid grid-cols-1 lg:grid-cols-2 max-w-7xl mx-auto my-6 px-4 relative xl:left-20 xl:-top-36">
                <div className="xl:ml-36 lg:mt-12 xl:mt-0">
                    <h3 className="mx-4 font-hof-clean text-gold text-5xl tracking-wide text-center lg:text-right cursor-default leading-tight">{t('homepage.lugar-donde-disfrutar.title')}</h3>
                    <div className="my-10 w-96 xl:w-80 mx-auto">
                        <p className="prose text-center lg:text-right mx-auto">{t('homepage.lugar-donde-disfrutar.content')}</p>
                        <div className="flex mt-10 lg:justify-end justify-center">
                            <CTAButton path={`/${t('common:routes.articles')}`} value={t('common:see-more')} />
                        </div>
                    </div>
                </div>
                <div className="sm:mx-auto lg:mx-0">
                    <figure className="flex drop-shadow-sm">
                        <Image src={`${process.env.NEXT_PUBLIC_FILES_IQC_MANAGER}/files/oasiz/homepage/bear.png`} alt={t('homepage.lugar-donde-disfrutar.title')} width="566" height="528" />
                    </figure>
                </div>
            </section>
        </div>
    )
}

export default LugarDondeDisfrutar
